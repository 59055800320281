<template>
  <validation-observer ref="observer">
    <v-card class="pa-0" :loading="loading">
      <v-card-text class="pa-0">
        <div class="pa-9 pa-md-6 pa-lg-9 mx-10">
          <v-container>
            <v-row class="mb-2">
              <v-col cols="12">
                <h3 class="page-title font-weight-bold">
                  床材の編集
                </h3>
                <span
                  >床材の編集を行います。編集後は過去のデータも変更されます。</span
                >
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="3" class="text-right">表示順序</v-col>
              <v-col cols="7">
                <validation-provider
                  v-slot="{ errors }"
                  name="display_order"
                  rules="between:1,99"
                >
                  <v-text-field
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    v-model.number="floorMaterial.display_order"
                    type="number"
                    class="form-text"
                    dense
                    hide-details="auto"
                    outlined
                    placeholder="1~99の数字を一つ入力してください。"
                    :disabled="disableForm"
                  >
                  </v-text-field>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="3" class="text-right">
                床材名
              </v-col>
              <v-col cols="7">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  placeholder="床材の表示名を入力してください"
                  v-model="floorMaterial.name"
                  :disabled="disableForm"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="3" class="text-right">
                データタイプ
              </v-col>
              <v-col cols="7">
                <v-select
                  outlined
                  hide-details
                  dense
                  placeholder="データタイプを選択して下さい"
                  v-model="floorMaterial.type"
                  item-text="name"
                  item-value="id"
                  :items="floorMaterialTypes"
                  :disabled="disableForm"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row align="center" v-if="floorMaterial.type === 0">
              <v-col cols="3" class="text-right">
                床材の色
              </v-col>
              <v-col cols="auto">
                <v-color-picker
                  hide-mode-switch
                  mode="hexa"
                  v-model="floorMaterial.color_code"
                  class="mx-auto"
                ></v-color-picker>
              </v-col>
            </v-row>

            <v-row align="center" v-if="floorMaterial.type === 1">
              <v-col cols="3" class="text-right">
                画像
              </v-col>
              <v-col cols="7">
                <v-img
                  contain
                  :src="preview_file"
                  :width="!floorMaterial.image ? '60%' : '100%'"
                  class="image_preview mb-2"
                />
                <div class="d-flex">
                  <v-btn text class="red--text" @click="removeFile()">
                    ― 削除
                  </v-btn>
                  <v-btn text class="blue--text pointer" @click="openFile()">
                    <v-icon left>mdi-plus</v-icon> アップロード
                  </v-btn>
                </div>
                <input
                  type="file"
                  style="display: none"
                  ref="uploader"
                  accept="image/*"
                  @change="onFilePicked"
                />
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="create-task-footer px-6">
          <v-row class="justify-end">
            <v-col cols="auto" class="flex-grow-1">
              <v-btn
                text
                rounded
                min-width="150"
                color="#AD4545"
                @click="deleteItem()"
                :loading="loading"
                :disabled="disableForm"
              >
                <v-icon left>
                  $trash
                </v-icon>
                削除する
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="mr-8"
                rounded
                min-width="150"
                text
                color="#828282"
                @click="$router.back()"
              >
                キャンセル
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                min-width="150"
                color="#4F55A7"
                class="white--text"
                @click="submit()"
                :loading="loading"
                :disabled="disableForm"
              >
                更新する
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </validation-observer>
</template>

<script>
import dayjs from '@/plugins/dayjs'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Edit',
  computed: {
    ...mapGetters(['getFloorMaterial']),
    disableForm() {
      return this.loading
    },
    preview_file: {
      get() {
        return this.preview
          ? this.preview
          : require('@/assets/images/placeholder-image.png')
      },
      set(value) {
        this.preview = value
      }
    },
    floorMaterial() {
      return this.getFloorMaterial
    }
  },
  data() {
    return {
      preview: null,
      // floorMaterial: {
      //   id: '',
      //   name: '',
      //   type: '',
      //   color_code: '',
      //   image: null
      // },
      floorMaterialTypes: [
        {
          id: 0,
          name: '色選択'
        },
        {
          id: 1,
          name: '画像'
        }
      ],
      loading: false
    }
  },

  mounted() {
    this.getDataFromApi()
  },

  methods: {
    ...mapMutations(['showModal']),
    async getDataFromApi() {
      this.loading = true
      await this.$store.dispatch('FLOOR_MATERIAL_GET', {
        id: this.$route.params.id
      })
      this.preview = this.getFloorMaterial.image_url
      this.loading = false
    },
    async submit() {
      this.$refs.observer.validate().then(async success => {
        if(!success){
          return
        }
      this.loading = true
      let formData = new FormData()
      formData.append('_method', 'PUT')
      formData.append('image', this.floorMaterial.image)
      for (const [key, value] of Object.entries(this.floorMaterial)) {
        if (key !== 'image')
          formData.append(key, value ? value : value == 0 ? 0 : '')
      }
      await this.$store
        .dispatch('FLOOR_MATERIAL_UPDATE', {
          id: this.floorMaterial.id,
          data: formData
        })
        .then(response => {
          if (response.status === 200) {
            this.$router.push({
              name: 'FloorMaterialManagement'
            })
          }
        })
      this.loading = false
      })
    },
    formatDate(e) {
      return dayjs(e).format('D')
    },
    openFile() {
      this.$refs.uploader.click()
    },
    onFilePicked(e) {
      this.floorMaterial.image = e.target.files[0]
      this.preview_file = URL.createObjectURL(this.floorMaterial.image)
    },
    removeFile() {
      this.floorMaterial.image = null
      this.preview = null
    },
    deleteItem() {
      this.showModal({
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },

    async processDelete() {
      this.loading = true
      await this.$store
        .dispatch('FLOOR_MATERIAL_DELETE', { id: this.$route.params.id })
        .then(response => {
          if (response.status === 200) {
            this.$router.push({
              name: 'FloorMaterialManagement',
              query: { status: 'success', message: response.data.data.message }
            })
          }
        })
      this.loading = false
    }
  }
}
</script>

<style lang="scss" src="./Edit.scss" scoped></style>
